@use 'animations';
@use 'breakpoints';
@use 'colors';
@use 'fonts';
@use 'sizes';
@use 'variables';

.navbar-menu-component {
  align-items: center;
  column-gap: 2rem;
  display: flex;
  position: relative;

  .subscription-link {
    @include colors.text-gradient;
  }

  &__avatar {
    align-items: center;
    background-color: colors.$color-primary-70;
    border-radius: 50%;
    color: colors.$color-primary-0;
    cursor: pointer;
    display: flex;
    font-size: fonts.$font-size-lg;
    font-weight: fonts.$font-weight-s;
    height: 5.8rem;
    justify-content: center;
    margin-bottom: 2.55rem;
    width: 5.8rem;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      height: 5.3rem;
      margin-bottom: 0;
      width: 5.3rem;
    }
  }

  &__button {
    align-items: center;
    display: flex;
  }

  &__menu-container {
    animation: fadeIn 250ms;
    background-color: colors.$color-primary-90;
    position: fixed;
    right: 0;
    top: sizes.$header-height-mobile;
    width: 100%;
    z-index: 10;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      background-color: colors.$color-secondary-65;
      border: 0.1rem solid colors.$color-primary-70;
      position: absolute;
      top: 6rem;
      width: auto;
    }

    &--desktop {
      border-radius: 0.5rem;
      display: none;
      width: 27.7rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        display: block;
      }
    }

    &--mobile {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100dvh - variables.$navBarHeight);
      justify-content: flex-end;
      padding: 4rem 2.5rem 2.8rem;
      width: 100%;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        display: none;
      }
    }
  }

  &__email-wrapper {
    display: flex;
  }

  &__email {
    color: rgba(255, 255, 255, 0.7);
    font-weight: fonts.$font-weight-s;
    margin-bottom: 3.2rem;
  }

  &__pro-tag {
    color: colors.$color-primary-0;
    margin-left: 1rem;
  }

  &__premium-button {
    margin-top: 4.3rem;
  }

  &__menu-header {
    @include fonts.font-text-7;

    align-items: center;
    color: colors.$color-primary-50;
    column-gap: 2.4rem;
    display: flex;
    height: 5.4rem;
    padding: 0 1.9rem 0 2rem;

    .email {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 80%;
    }

    .pro-tag-component {
      color: colors.$color-primary-0;
      font-size: fonts.$font-size-s;
    }
  }

  &__separator {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
    margin: 4.7rem 0 2rem;
    width: 100%;
  }

  &__sign-up-button {
    position: absolute;
    top: calc(50% - 4.3rem);
  }
}
