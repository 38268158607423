@use 'breakpoints';
@use 'colors';

/* Fonts */

//Font
$lato: Lato, sans-serif;
$sfpro: SFPro, sans-serif;

//Font-weight
$font-weight-xs: 300;
$font-weight-s: 400;
$font-weight-md: 500;
$font-weight-lg: 600;
$font-weight-xl: 700;

//Font-size
$font-size-xs: 1.2rem;
$font-size-s: 1.5rem;
$font-size-md: 1.7rem;
$font-size-lg: 2rem;
$font-size-xl: 2.6rem;
$font-size-xxl: 3rem;
$font-size-3xl: 3.2rem;
$font-size-4xl: 5rem;
$font-size-5xl: 7rem;
$font-oversize: 9rem;

@mixin font($size, $weight, $line-height: 1.8rem, $family: $sfpro) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
}

@mixin font-title-2 {
  @include font($font-size-xxl, $font-weight-md, 6rem);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-4xl, $font-weight-md, 6rem);
  }
}

@mixin font-title-3 {
  @include font($font-size-xxl, $font-weight-xl);

  color: colors.$color-primary-0;
}

@mixin font-title-4 {
  @include font($font-size-lg, $font-weight-s);
}

@mixin font-text-4 {
  @include font($font-size-xl, $font-weight-md, 3.7rem);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-xl, $font-weight-md, 3.7rem);
  }
}

@mixin font-text-5 {
  @include font($font-size-lg, $font-weight-s, 2.4rem);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-lg, $font-weight-s, 2.4rem);
  }
}

@mixin font-text-6 {
  @include font($font-size-md, $font-weight-md, 2rem);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-md, $font-weight-md, 2rem);
  }
}

@mixin font-text-7 {
  @include font($font-size-s, $font-weight-md);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-s, $font-weight-md);
  }
}

@mixin font-text-8 {
  @include font($font-size-xs, $font-weight-md, 1.4rem);

  color: colors.$color-primary-0;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    @include font($font-size-xs, $font-weight-md, 1.4rem);
  }
}
