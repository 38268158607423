@use 'colors';
@use 'fonts';
@use 'breakpoints';

.menu-item-component {
  @include fonts.font-text-6;

  align-items: center;
  background-color: colors.$color-primary-90;
  border-radius: 0.5rem;
  color: colors.$color-primary-0;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin: 0 0 3rem;
  width: 100%;

  @media screen and (min-width: breakpoints.$breakpoint-tablet) {
    background-color: colors.$color-secondary-65;
    color: colors.$color-primary-50;
    height: 4.6rem;
    justify-content: flex-start;
    margin: 0 0.3rem;
    width: auto;
  }

  &__text {
    font-size: 1.8rem;
    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      font-size: 1.6rem;
    }
  }

  &__icon {
    padding: 0 1.5rem 0 2rem;
  }

  &:last-child {
    margin-bottom: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }
}
