/* Sizes */

$form-width: 38.8rem;

$header-height-desktop: 8.6rem;

$header-height-mobile: 6.8rem;

$single-line-footer-height: 11.3rem;

$single-line-footer-height-high-button: 12.1rem;

$button-large-width: 59rem;

$button-small-height: 5rem;
