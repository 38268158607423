@use 'breakpoints';
@use 'colors';
@use 'fonts';
@use 'sizes';

.home-view {
  display: flex;
  flex-direction: column;

  .try-stemz-web-button-container {
    &__button {
      border-radius: 1rem;
      height: unset;
      padding: unset;

      .button-component__label {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 1.8rem 5.4rem;

        .label-main-text {
          color: colors.$color-primary-0;
          font-size: fonts.$font-size-lg;
          font-weight: fonts.$font-weight-lg;
          line-height: 2.4rem;

          @media screen and (min-width: breakpoints.$breakpoint-tablet) {
            font-size: 2.4rem;
            line-height: 2.9rem;
          }
        }

        .label-main-subtext {
          color: rgba(255, 255, 255, 0.7);
          font-size: fonts.$font-size-s;
          font-weight: fonts.$font-weight-md;
          line-height: 1.8rem;

          @media screen and (min-width: breakpoints.$breakpoint-tablet) {
            font-size: 1.8rem;
            line-height: 2.1rem;
          }
        }
      }
    }
  }

  &__header {
    align-items: center;
    background: #000;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 1.8rem 2.4rem;
    position: fixed;
    right: 0;
    top: 0;
    transition: top 250ms ease-in-out;
    z-index: 10;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      left: 0;
      padding: 1.8rem 5em;
      right: 0;
    }

    &.hidden {
      top: -9.2rem;
      transition: top 250ms ease-in-out;
    }

    .stemz-logo-container {
      align-items: center;
      column-gap: 0.6rem;
      display: flex;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        column-gap: 1.1rem;
      }
    }

    .stemz-powered-by-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.3rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        column-gap: 1.1rem;
        flex-direction: row;
        row-gap: 0;
      }
    }

    .stemz-logo {
      height: 3.5rem;
      width: 3.5rem;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        height: 5rem;
        width: 5rem;
      }
    }

    .powered-by-frame__container {
      margin-top: -0.3rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .powered-by-frame {
      height: auto;
      width: 9rem;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        width: 10.8rem;
      }
    }

    .powered-by-mention {
      color: colors.$color-primary-0;
      font-size: 1.4rem;
      font-weight: fonts.$font-weight-xl;
      line-height: 1.7rem;
      margin-bottom: -0.3rem;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        margin-bottom: 0;
      }
    }

    &__actions {
      align-items: center;
      display: flex;
      gap: 5rem;

      &__link {
        cursor: pointer;

        &:hover {
          color: colors.$color-primary-50;
        }
      }

      &__button {
        border-radius: 1rem;
        font-size: 1.35rem;
        height: 3.9rem;
        line-height: 1.6rem;
        padding: 0 1.3rem;

        @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
          font-size: 1.4rem;
          padding: 0 2.5rem;
        }

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          font-size: 1.75rem;
          height: 5.3rem;
          line-height: 2.1rem;
          padding: 0 4rem;
        }
      }
    }
  }

  .main-section {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      background: #000;
      padding: 0;
    }

    &__content {
      align-items: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-between;
      padding: 16vh 2.4rem 8vh;
      position: relative;
      text-align: center;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        align-items: flex-start;
        background: transparent;
        justify-content: center;
        margin: auto 7rem;
        padding: 0;
        text-align: left;
        width: 50%;
      }
    }

    .image-container {
      position: relative;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        width: 100%;
      }

      .background-image {
        left: 0;
        min-height: 100vh;
        object-fit: cover;
        opacity: 20%;
        position: absolute;
        top: 0;
        width: 100%;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          height: 100vh;
          width: 135%;
        }
      }
    }

    .title {
      color: colors.$color-primary-0;
      font-size: 4rem;
      font-weight: fonts.$font-weight-xl;
      letter-spacing: 0.02em;
      line-height: 4.8rem;
      margin: 0;
      margin-block-end: 0;
      margin-block-start: 0;
      margin-inline-end: 0;
      margin-inline-start: 0;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        font-size: 6rem;
        line-height: 7.2rem;
        margin: 0;
      }

      .text-gradient {
        @include colors.text-gradient;
      }
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.6rem;
      font-weight: fonts.$font-weight-s;
      letter-spacing: 0.02em;
      line-height: 2.2rem;
      margin: 1.5rem 0;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        margin: 2.5rem 0 0;
      }
    }

    .button-wrapper-container {
      bottom: 8rem;
      min-width: 30rem;
      position: absolute;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        bottom: 12rem;
      }

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        position: initial;
      }
    }

    .store-logo-container {
      align-items: center;
      column-gap: 1rem;
      display: flex;
      justify-content: space-between;
      margin: 0;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        column-gap: 2rem;
        margin-top: 4.4rem;
      }

      .store-logo {
        width: 12rem;

        @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
          height: 4.6rem;
          width: 15.5rem;
        }

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          height: 5.6rem;
          width: 20rem;
        }
      }
    }

    .try-stemz-web-button-container {
      align-items: center;
      display: flex;
      margin-top: 2rem;
      width: 100%;

      @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
        margin-top: 3.5rem;
      }

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        margin-top: 5rem;
      }

      .button-component {
        max-width: 41.1rem;
        width: 100%;
      }

      .button-component__label {
        align-items: center;
      }

      img.arrow-icon {
        height: 4.2rem;
        margin-left: 4.6rem;
        position: absolute;
        right: 2.7rem;
        top: 1.2rem;
        width: 4.2rem;
      }
    }

    .scroll-icon-container {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding-bottom: 4rem;
      position: absolute;
      width: 100%;
      z-index: 2;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        background: unset;
      }

      img {
        height: 2.3rem;
        width: 16.8rem;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          height: 2.6rem;
          width: 19rem;
        }
      }
    }
  }

  .section-container {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 2.4rem;
    position: relative;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      padding: 12.2rem 0;
    }
  }

  .section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 0;
    text-align: center;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      flex-direction: row;
      padding: 0;
      text-align: left;
    }

    .argument-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        align-items: flex-start;
        margin: 0 20%;
      }

      img {
        width: 100%;
      }
    }

    .illustration-container {
      align-items: center;
      display: flex;
      justify-content: center;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        margin: 0 20% 0 0;
      }

      img {
        position: relative !important;
        width: 100%;
      }
    }
  }

  .section-title {
    font-size: 2.9rem;
    font-weight: fonts.$font-weight-xl;
    letter-spacing: 0.01em;
    line-height: 4.1rem;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      font-size: 5.5rem;
      line-height: 7rem;
    }
  }

  .section-text-content {
    font-size: 1.6rem;
    font-weight: fonts.$font-weight-s;
    letter-spacing: 0.01em;
    line-height: 2.4rem;
    margin-top: 1rem;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      font-size: 2.2rem;
      line-height: 3.3rem;
      margin-top: 2rem;
    }
  }

  .try-button {
    border-radius: 1rem;
    height: 6.1rem;
    margin-bottom: 4rem;
    margin-top: 2.5rem;
    width: 100%;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      margin-bottom: 0;
      margin-top: 4.5rem;
      width: 29rem;
    }

    img.arrow-icon {
      height: 2.9rem;
      margin-left: 5.8rem;
      position: absolute;
      right: 2rem;
      width: 2.9rem;
    }
  }

  .section-0 {
    background-color: #3c3a3d;

    &__title {
      color: colors.$color-primary-0;
    }

    &__text-content {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .section-1 {
    background-color: colors.$color-primary-0;

    &__title {
      color: colors.$color-primary-90;
    }

    &__text-content {
      color: #636366;
    }
  }

  .section-2 {
    background: linear-gradient(180deg, #131f30 12.87%, #040504 53.96%);
    padding: 5rem 0 0;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      background: linear-gradient(270deg, #040504 55.82%, rgba(4, 5, 4, 0.3) 59.77%, #131f30 100%), #000000;
      padding: 0;
    }

    .argument-container {
      padding: 0 2.4rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        padding: 5rem 0;
      }
    }

    .section-container {
      min-height: 46vh;
      padding: 0;
      width: 100%;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        min-height: 70vh;
      }
    }

    .illustration-container {
      background: linear-gradient(180deg, #0c0b10 50%, #131f30 100%);
      background-image: url('/medias/background-section-3.png');
      background-size: cover;
      height: 100%;
      left: 0;

      position: absolute;
      top: 0;
      width: 100%;
    }

    &__title {
      color: colors.$color-primary-0;
    }

    &__text-content {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .section-want-to-try {
    background-color: colors.$color-primary-90;
    padding-bottom: 7.5rem;
    position: relative;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      padding-bottom: 16rem;
      padding-top: 12.2rem;
    }

    &__content {
      align-items: center;
      background-color: colors.$color-primary-90;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 6.2rem 2.4rem 0;
      position: relative;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        margin: auto;
        padding: 0;
        width: 50%;
      }

      &__title {
        color: colors.$color-primary-0;
        font-size: 3.5rem;
        font-weight: fonts.$font-weight-xl;
        letter-spacing: 0.02em;
        line-height: 140%;
        text-align: center;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          font-size: 5.8rem;
        }
      }

      &__subtitle {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.6rem;
        font-weight: fonts.$font-weight-s;
        letter-spacing: 0.02em;
        line-height: 150%;
        margin-top: 1.2rem;
        text-align: center;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          font-size: 2.5rem;
          margin-top: 2.5rem;
        }
      }

      .try-stemz-web-button-container {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 3.5rem;
        width: 100%;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          margin-top: 5rem;
          width: 70%;
        }

        .button-component {
          max-width: 30rem;
          width: 100%;
        }

        .button-component__label {
          align-items: center;
        }

        img.arrow-icon {
          height: 4.2rem;
          margin-left: 4.6rem;
          position: absolute;
          right: 2.7rem;
          top: 1.2rem;
          width: 4.2rem;
        }
      }

      .store-logo-container {
        align-items: center;
        column-gap: 1rem;
        display: flex;
        margin-top: 3.5rem;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          column-gap: 2rem;
          margin-top: 4.4rem;
        }

        .store-logo {
          width: 12rem;

          @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
            height: 4.6rem;
            width: 15.5rem;
          }

          @media screen and (min-width: breakpoints.$breakpoint-tablet) {
            height: 5.6rem;
            width: 20rem;
          }
        }
      }
    }
  }

  .section-legal {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 4rem;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
      padding: 12.2rem 10% 4rem;
    }

    .license {
      color: rgba(255, 255, 255, 0.6);
      font-size: 1.6rem;
      font-weight: fonts.$font-weight-s;
      letter-spacing: -0.018rem;
      line-height: 2.7rem;
      margin-top: 6rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        font-size: 1.8rem;
        margin: 0;
      }
    }

    .links-container {
      column-gap: 4.6rem;
      display: flex;

      a.link {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.6rem;
        font-weight: fonts.$font-weight-s;
        line-height: 150%;

        @media screen and (min-width: breakpoints.$breakpoint-tablet) {
          font-size: 1.8rem;
          font-weight: fonts.$font-weight-md;
        }
      }
    }
  }
}
