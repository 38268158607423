@use 'colors';
@use 'fonts';

.button-component {
  align-items: center;
  background: var(--background);
  border-radius: 1rem;
  box-sizing: border-box;
  color: var(--color);
  cursor: pointer;
  display: flex;
  height: 5rem;
  padding: 0 4rem;
  position: relative;
  white-space: nowrap;

  &.primary {
    --background: linear-gradient(90deg, #de005b 70%, #d16536 100%);
    --backgroundHover: #de005b;
    --backgroundPressed: #b0034a;

    --color: #{colors.$color-primary-0};

    --backgroundOutlined: transparent;
    --backgroundOutlinedHover: #{colors.$color-brand-gradient-90};
    --backgroundOutlinedPressed: #b0034a;

    --borderColorOutlined: #{colors.$color-brand-gradient-90};

    --colorOutlined: #{colors.$color-brand-gradient-90};
    --colorOutlinedHover: #{colors.$color-primary-0};
    --colorOutliedPressed: #{colors.$color-primary-0};
  }

  &.secondary {
    border: 0.1rem solid var(--border);
    --border: #{colors.$color-primary-0};
    --background: #{colors.$color-primary-90};
    --backgroundHover: #{colors.$color-primary-50};
    --backgroundPressed: #{colors.$color-primary-65};

    --color: #{colors.$color-primary-0};

    --backgroundOutlined: #{colors.$color-primary-90};
    --backgroundOutlinedHover: #{colors.$color-primary-50};
    --backgroundOutlinedPressed: #{colors.$color-primary-65};

    --borderColorOutlined: #{colors.$color-primary-50};

    --colorOutlined: #{colors.$color-primary-0};
    --colorOutlinedHover: #{colors.$color-primary-0};
    --colorOutliedPressed: #{colors.$color-primary-0};
  }

  &.tertiary {
    border: 0.1rem solid rgba(255, 255, 255, 0.1);
    --color: #{colors.$color-primary-0};
    --background: #{colors.$color-primary-70};

    &:hover:enabled,
    &.pseudoclass--hover {
      --background: #{colors.$color-tertiary-10};
    }
  }

  &.red {
    --background: #{colors.$color-error-70};
    --backgroundHover: #{colors.$color-error-80};
    --backgroundPressed: #{colors.$color-error-90};

    --color: #{colors.$color-primary-0};

    --backgroundOutlined: #{colors.$color-error-70};
    --backgroundOutlinedHover: #{colors.$color-error-80};
    --backgroundOutlinedPressed: #{colors.$color-error-90};

    --borderColorOutlined: #{colors.$color-error-80};

    --colorOutlined: #{colors.$color-primary-0};
    --colorOutlinedHover: #{colors.$color-primary-0};
    --colorOutliedPressed: #{colors.$color-primary-0};
  }

  &.pink {
    --background: #{colors.$system-primary};
    --color: #{colors.$color-primary-0};

    &:hover:enabled,
    &.pseudoclass--hover {
      --background: #ee4188;
    }

    &.outline {
      --background: transparent;
      --border: 0.1rem solid #{colors.$color-primary-0};
      --color: #{colors.$color-primary-0};

      &:hover:enabled,
      &.pseudoclass--hover {
        border: 0.1rem solid #{colors.$system-primary};
        --color: #{colors.$system-primary};
      }

      .button-component__label {
        -webkit-text-fill-color: #{colors.$color-primary-0};
      }
    }
  }
  @include fonts.font-text-6;

  &:hover:enabled,
  &.pseudoclass--hover {
    &:not(.primary) &:not(.pink) {
      background: var(--backgroundHover);
    }
  }

  &:active:enabled,
  &.pseudoclass--active {
    background: var(--backgroundPressed);
  }

  &.primary {
    background-position: 100% 0;
    background-size: 200%;
    transition: background-position 3s cubic-bezier(0.19, 1, 0.22, 1);

    &:hover:enabled,
    &.pseudoclass--hover {
      background-position: 0 0;
    }
  }

  &:not(.primary, .outline) {
    transition: background 1s cubic-bezier(0.19, 1, 0.22, 1);

    &:active:enabled,
    &.pseudoclass--active {
      background: var(--backgroundPressed);
      transition: background 0s;
    }
  }

  &.outline,
  &.pseudoclass--outline {
    background: var(--backgroundOutlined);
    border: 0.1rem solid;
    border-image-slice: 1;
    border-image-source: var(--borderColorOutlined);
    padding: 0 3.9rem;

    &.primary {
      background-position: 100% 0;
      background-size: 300%;
    }

    .button-component__label {
      @include colors.text-gradient(var(--colorOutlined));

      svg {
        color: var(--color);
      }
    }
  }

  &.ghost {
    background: inherit;
    color: colors.$color-primary-0;
    transition: background 1s cubic-bezier(0.19, 1, 0.22, 1);

    &.pink {
      color: colors.$system-primary;

      &:hover:enabled,
      &.pseudoclass--hover {
        background: var(--backgroundHover);
        color: colors.$color-primary-0;
      }
    }

    &:hover:enabled,
    &.pseudoclass--hover {
      background: colors.$color-primary-80;
    }

    &:active:enabled,
    &.pseudoclass--active {
      background: colors.$color-primary-65;
    }
  }

  &:disabled {
    cursor: unset;
    opacity: 0.4;
  }

  &.full-width {
    width: 100%;
  }

  &__label {
    align-items: center;
    column-gap: 1rem;
    display: flex;
  }

  &--loading {
    .button-component__progress-circle {
      margin-left: 1.2rem;
    }
  }
}

.button-loader-component {
  height: 12rem !important;
}
