@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes slide {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(-150%);
  }
}

@keyframes slide2 {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-200%);
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
