@use 'breakpoints';
@use 'colors';
@use 'fonts';
@use 'sizes';
@use 'variables';

.top-navbar-component {
  align-items: center;
  background: #000;
  color: colors.$color-primary-0;
  height: variables.$navBarHeight;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  &.hidden {
    top: -9.2rem;
    transition: top 250ms ease-in-out;
  }

  &__content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-inline: 2rem 2rem;

    @media screen and (min-width: breakpoints.$breakpoint-tablet) {
      margin-inline: 1.6rem 3.4rem;
    }

    &__navigation {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-right: 2rem;
      width: 100%;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        gap: 4rem;
        justify-content: unset;
        padding-right: 0;
      }

      .logo-container {
        align-items: center;
        column-gap: 0.6rem;
        cursor: pointer;
        display: flex;

        @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
          column-gap: 1.1rem;
        }

        &__brand {
          display: flex;
          flex-direction: column;
          row-gap: 0.3rem;

          @media screen and (min-width: breakpoints.$breakpoint-tablet) {
            column-gap: 1.1rem;
            display: unset;
            flex-direction: row;
            row-gap: 0;
          }

          &__text {
            color: colors.$color-primary-0;
            font-size: 1.4rem;
            font-weight: fonts.$font-weight-xl;
            line-height: 1.7rem;
            margin-bottom: -0.3rem;

            @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }

            @media screen and (min-width: breakpoints.$breakpoint-tablet) {
              margin-bottom: 0;
            }
          }

          &__image {
            margin-top: -0.3rem;

            @media screen and (min-width: breakpoints.$breakpoint-tablet) {
              align-items: center;
              display: flex;
              margin-bottom: 0;
              margin-top: 0;
            }

            .powered-by-frame {
              height: auto;
              width: 9rem;

              @media screen and (min-width: breakpoints.$breakpoint-mobile-fold) {
                width: 10.8rem;
              }
            }
          }
        }
      }

      &__item {
        cursor: pointer;

        &:hover {
          color: colors.$color-primary-50;
        }

        &.active {
          color: colors.$color-secondary-50;
        }
      }
    }
  }
}
