/* Colors */

// Brand
$color-brand-gradient-90: linear-gradient(90deg, #de005b 0%, #d16536 100%);
$color-brand-gradient-180: linear-gradient(180deg, #de005b 0%, #d16536 100%);
$color-blur-gradient: linear-gradient(90deg, rgba(222, 0, 91, 0.1) 0%, rgba(209, 101, 54, 0.1) 100%);
$color-brand-primary: #de005b;
$color-brand-secondary: #d16536;
$color-mwm-orange: #ff5301;

// Primary
$color-primary-0: #ffffff;
$color-primary-50: #9e9e9e;
$color-primary-60: #ffffff99;
$color-primary-65: #6c6c6c;
$color-primary-70: #1d1d1d;
$color-primary-80: #171717;
$color-primary-85: #151515;
$color-primary-90: #101010;

// Secondary
$color-secondary-50: #e70e67;
$color-secondary-65: #2c2c2e;
$color-secondary-75: #1e1d1d;

// Tertiary
$color-tertiary-10: #010101;

// System
$system-gray-06-dark: #1c1c1e;
$system-green-dark: #30d158;
$system-primary: #e70e67;
$system-secondary: #1e1d1d;

// Error
$color-error-70: #ab2b11;
$color-error-80: #cb3517;
$color-error-90: #97220b;
$color-error-v2: #9f2619;

// Success
$color-success-70: #135b30;

// Disabled
$color-disabled-30: #ffffff4d;

@mixin text-gradient($color: $color-brand-gradient-90) {
  background: $color;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
